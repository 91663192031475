import { toggleCurtains } from "../fragment/countdown-timer/CountdownTimerSlice";
import { decrement, increment } from "../fragment/counter/CounterSlice";
import { play, playFor, unload } from "../fragment/media-player/MediaPlayerSlice";
import { appear } from "../fragment/neon-title/NeonTitleSlice";

const MidiConfig =
{ 
    
    triggers:
    [
        { note: 11, function: decrement() },
        { note: 12, function: increment() },

        { note: 21, function: appear({ text: "Exclusive", amount: 8, color: "#f46036", duration: 5000, }) },
        { note: 22, function: appear({ text: "TimeWaster", amount: 8, color: "#52ffb8", duration: 5000, }) },
        { note: 22, function: appear({ text: "Serious Ravequest", amount: 8, color: "#35d327", duration: 5000, }) },

        { note: 15, function: playFor({ref: "beetje zuipen", duration: 9.5}) },

        { note: 25, function: play("wat een goed idee")},
        { note: 26, function: play("je doet maar") },

        { note: 61, function: play("crowd") },
        { note: 62, function: play("escalatievogel") },
        { note: 63, color: 0x3D, function: play("pornhug")},

        { note: 71, color: 0x57, function: play("rct cash")},
        { note: 72, color: 0x57, function: play("duolingo")},
        { note: 73, color: 0x57, function: play("dankewel")},
        { note: 74, color: 0x57, function: playFor({ref: "hollebollegijs", duration: 1})},

        { note: 81, function: play("airhorn") },
        { note: 82, function: play("grolsch") },
        { note: 83, function: play("shotjee") },
        { note: 84, color: 0x0b, function: play("jägermeistah") },
        { note: 85, color: 0x0b, function: play("not enough jäger") },
        { note: 86, color: 0x0b, function: play("fuck you") },

        { note: 88, color: 0x04, function: toggleCurtains()},
        { note: 18, color: 0x05, function: unload() }, // Stop all media
    ]
}

export { MidiConfig }
