import './QrCode.css';
import { QRCodeCanvas } from 'qrcode.react';

const QrCode = () => {
  return (
    <div className="qrcode">
      <p className="text">
        Promo Pool
      </p>
      <div className="qr-container">
        <QRCodeCanvas
          value="https://musicalkonviction.promopool.eu/mk033"
          fgColor='#111'
          marginSize={2}
          size={200}
          height={'100%'}
          width={'100%'} />
      </div>
    </div>
  );
};

export default QrCode;
