import { createSlice } from '@reduxjs/toolkit'

export interface CounterProps {
    value: number
}

const initialState: CounterProps = {
    value: 75 // 2025-03-25
}

export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1
        },
        decrement: (state) => {
            state.value -= 1
        }
    },
})

export const { increment, decrement } = counterSlice.actions
export default counterSlice.reducer